import React, { useEffect, useRef, useState } from 'react';
import LiveChat from 'react-livechat';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  getInstanceName,
  getLiveChatParams,
  isClient,
  isUserLogged,
  SIGNING_AUDIT_TRAIL_TYPE,
} from '../helpers/utils';
import {
  addToCart,
  makeOrderWithSavedCart,
  setAuditTrailStatus,
  setAuditTrialData,
  setLoader,
  setModal,
} from '../store/actions';
import {
  selectAndCheckIfAgreementSignedForCurrentPlanInTheCart,
  selectBillingAddresses,
  selectCart,
  selectCoinPaymentDiscount,
  selectConditionToShowPurchaseOrSignButton,
  selectIbpProduct,
  selectIfIbpInTheCart,
  selectIfUserNeedToSignAgreement,
  selectPaymentCardForCvvConfirm,
  selectPaymentsWithDefault,
  selectPreAuthorizeModalCondition,
  selectShowVatAmount,
  showAutoRenewalMessage,
} from '../store/selectors';
import {
  selectAuditTrial,
  selectCalculatedPrice,
  selectElectronicSignments,
  selectEntityUserDetails,
  selectEntityUserSubscriptions,
} from '../store/selectors/entities';
import { selectChosenPaymentMethod, selectModal } from '../store/selectors/global';
import styles from '../styles/pages/checkout.module.scss';
import SignPdfModal from '../components/sign-pdf-modal';
import showPdfAgreement from '../components/checkout-total/showPdfAgreement';
import Button from '../components/button';

import verificationstyles from '../styles/pages/verifications.module.scss';
import { ButtonLoaderTypes } from '../constants/types';
import BillingAddressForm from '../components/billing-address-form';
import setNotification from '../helpers/notifications';

const query = graphql`
  query {
    verifyIcon: file(relativePath: { eq: "verify-success.png" }) {
      childImageSharp {
        fixed(width: 162) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bgLeft: file(relativePath: { eq: "confeti-left.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgRight: file(relativePath: { eq: "confeti-right.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  billingAddresses: selectBillingAddresses,
  selectedPaymentMethod: selectChosenPaymentMethod,
  modal: selectModal,
  userDetails: selectEntityUserDetails,
  showVat: selectShowVatAmount,
  savedMethods: selectPaymentsWithDefault,
  showAutoRenewal: showAutoRenewalMessage,
  preAuthorizeCondition: selectPreAuthorizeModalCondition,
  electronicSignments: selectElectronicSignments,
  isAgreementSignedForCurrentCart: selectAndCheckIfAgreementSignedForCurrentPlanInTheCart,
  showPurchaseOrSign: selectConditionToShowPurchaseOrSignButton,
  cvvCardForConfirmation: selectPaymentCardForCvvConfirm,
  coinPaymentDiscount: selectCoinPaymentDiscount,
  calculatedPrice: selectCalculatedPrice,
  auditTrial: selectAuditTrial,
  ibpProduct: selectIbpProduct,
  isIbpInTheCart: selectIfIbpInTheCart,
  shouldSignAgreement: selectIfUserNeedToSignAgreement,
  subscriptions: selectEntityUserSubscriptions,
});

const Checkout = () => {
  const dispatch = useDispatch();
  const imgData = useStaticQuery(query);

  const [showSignPdf, toggleSignPdf] = useState(false);
  const liveChatRef = useRef(null);

  const {
    cartData,
    billingAddresses,
    selectedPaymentMethod,
    modal,
    userDetails,
    electronicSignments,
    calculatedPrice,
    auditTrial,
    subscriptions,
    ibpProduct,
  } = useSelector(stateSelector);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
      dispatch(setLoader(false));
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const liveChatParams = getLiveChatParams(userDetails, subscriptions);

  const changeAuditTrailStatus = (step, externalId) => {
    dispatch(setAuditTrailStatus({ step, externalId }));
  };

  useEffect(() => {
    try {
      localStorage.removeItem('audit-trial-id');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('CHECKOUT setAuditTrialData', err);
    } finally {
      dispatch(setAuditTrialData({ id: '', step: 0 }));
    }
  }, []);

  useEffect(() => {
    const isRenew = cartData?.products?.some((product) => product.isRenew);
    const isIBIBought = subscriptions?.some((item) => item.name === 'Publisher');

    if (isIBIBought && !isRenew) {
      if (ibpProduct) {
        dispatch(addToCart('product', ibpProduct));
      }
    }
  }, [ibpProduct, subscriptions, cartData, selectedPaymentMethod?.method]);

  useEffect(() => {
    if (liveChatRef?.current?.disable_sounds) {
      liveChatRef?.current?.disable_sounds();
    }
  }, [liveChatRef?.current]);

  const submitPayment = () => dispatch(makeOrderWithSavedCart());

  const siteName = getInstanceName();

  const showPdf = (action) => {
    if (!isClient) {
      return;
    }
    try {
      // eslint-disable-next-line consistent-return
      return showPdfAgreement(action, userDetails);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while showing PDF Document');
    }
  };

  const toggleModal = (name = '') => {
    dispatch(setModal(name));
  };

  const showAgreement = async () => {
    if (!billingAddresses.length) {
      toggleModal('newAddress');
      setNotification('info', {
        message:
          'To sign the publisher agreement, we need your billing address. Please add it first to continue.',
        title: '',
      });
      return;
    }

    await changeAuditTrailStatus(SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step);
    toggleSignPdf(true);
  };

  const isDisabled = isClient && !isReady;

  return (
    <>
      <SEO title={siteName} />
      <Layout pageWrapperClassName="page_wraper">
        <div className="container">
          <div className={verificationstyles.relative}>
            <BackgroundImage
              Tag="div"
              className={verificationstyles.summary_bg_overlay}
              fluid={[imgData.bgLeft.childImageSharp.fluid, imgData.bgRight.childImageSharp.fluid]}
              backgroundColor="transparent"
            />

            <>
              <div className={clsx(verificationstyles.wrapper, verificationstyles.verifySuccess)}>
                <div className={verificationstyles.title}>
                  You are Almost
                  <br />
                  there...
                </div>

                <div className={clsx(verificationstyles.textInfo, verificationstyles.long)}>
                  We are generating your agreement. We have successfully verified you.
                  <br />
                  <br />
                  Please click the button below to finalize your sign up.
                </div>

                <div className={verificationstyles.submitButton}>
                  <Button
                    fullWidth
                    size="large"
                    disabled={isDisabled}
                    className={styles.submitButton}
                    onClick={showAgreement}
                    loaderType={ButtonLoaderTypes.PURCHASE}
                  >
                    Continue and Sign Agreement
                  </Button>
                </div>
              </div>
            </>
          </div>
        </div>

        {modal === 'newAddress' && (
          <BillingAddressForm toggleModal={toggleModal} modal="newAddress" />
        )}

        {showSignPdf &&
          (calculatedPrice?.totalAmount === 0 ||
            (calculatedPrice?.totalAmount > 0 && selectedPaymentMethod?.method)) && (
            <SignPdfModal
              isModal
              show={showSignPdf}
              toggle={toggleSignPdf}
              onSubmit={submitPayment}
              userDetails={userDetails}
              cartData={cartData}
              electronicSignments={electronicSignments}
              showPdf={() => showPdf('getBlob')}
              billingAddresses={billingAddresses}
              setAuditTrialStatus={changeAuditTrailStatus}
              externalId={auditTrial.id}
              calculatedPrice={calculatedPrice}
            />
          )}

        {isUserLogged() && (
          <LiveChat
            visitor={{
              name: `${userDetails?.firstName} ${userDetails?.lastName}`,
              email: userDetails?.email,
            }}
            license={process.env.GATSBY_LIVECHAT_LICENSE}
            onChatLoaded={(ref) => {
              liveChatRef.current = ref;
            }}
            params={liveChatParams}
          />
        )}
      </Layout>
    </>
  );
};

Checkout.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Checkout;
