import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './checkbox.module.scss';

const Checkbox = (props) => {
  const { checked, onChange, children, errorMessage, disabled } = props;

  const onCheck = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    onChange();
  };

  const renderIcon = () => {
    if (checked) {
      return (
        <div className={clsx(styles.iconWrapper, styles.iconWrapperChecked)}>
          <i className="icon-check-small" />
        </div>
      );
    }

    return <div className={clsx(styles.iconWrapper)} />;
  };

  return (
    <button
      type="button"
      onClick={onCheck}
      className={clsx(
        styles.wrapper,
        errorMessage ? styles.error : null,
        disabled && styles.disabled
      )}
    >
      {renderIcon()}
      {children}
    </button>
  );
};

Checkbox.defaultProps = {
  errorMessage: null,
  children: null,
  disabled: false,
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  errorMessage: PropTypes.string,
};

export default Checkbox;
