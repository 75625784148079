import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import paymentSaga from './paymentSaga';
import refreshTokenSaga from './refreshTokenSaga';
// eslint-disable-next-line import/no-cycle
import tokenSaga from './tokenSaga';
// import liveChatSaga from './liveChatSaga';
// eslint-disable-next-line import/no-cycle
import setNotification from '../helpers/notifications';
import getRedirectionToken from '../services/api/actions/getRedirectionToken';
import getToken from '../services/api/actions/getToken';
import signUp from '../services/api/actions/signUp';
import {
  addToCart,
  getSignedAgreement,
  preselectCoupon,
  setAuditTrialData,
  setLoader,
  setUserStartCountry,
} from '../store/actions';
import authSaga from './authSaga';
import errorSaga from './errorSaga';

import { createNavigateTo, pageLinks } from '../helpers/navigation';
import planCombiner from '../helpers/planCombiner';
import { getSearchParam } from '../helpers/url';
import {
  getEnvConfig,
  getPortalLink,
  isClient,
  isUserLogged,
  SIGNING_AUDIT_TRAIL_TYPE,
} from '../helpers/utils';
import addBillAddress from '../services/api/actions/addBillAddress';
import addPaymentCard from '../services/api/actions/addPaymentCard';
import changeProfileInfo from '../services/api/actions/changeProfileInfo';
import checkSorUser from '../services/api/actions/checkSorUser';
import createPayment from '../services/api/actions/createPayment';
import deleteBillAddress from '../services/api/actions/deleteBillAddress';
import getCouponById from '../services/api/actions/getCouponById';
import getFastshopingPlans from '../services/api/actions/getFastshopingPlans';
import getFastshopingProducts from '../services/api/actions/getFastshopingProducts';
import getMetaData from '../services/api/actions/getMetaData';
import getUserDetails from '../services/api/actions/getUserDetails';
import getUserRefferal from '../services/api/actions/getUserRefferal';
import getUserSubscriptions from '../services/api/actions/getUserSubscriptions';
import recoverEmail from '../services/api/actions/recoverEmail';
import renewSubscription from '../services/api/actions/renewSubscription';
import setAuditTrail from '../services/api/actions/setAuditTrail';
import setPaymentAsDefault from '../services/api/actions/setPaymentAsDefault';
import updateBillAddress from '../services/api/actions/updateBillAddress';
import updateUserSocialNetworkLinks from '../services/api/actions/updateUserSocialNetworkLinks';
import upgradeSubscription from '../services/api/actions/upgradeSubscription';
import { isLocalStorageAvailable } from '../services/storage';
import * as AppConstants from '../store/constants';
import { FETCH_REFFERIAL_INFO, SET_PRODUCTS } from '../store/constants';
import { selectCart, selectIbpProduct } from '../store/selectors';
import {
  selectAuditTrial,
  selectEntityUserDetails,
  selectExpectedSubscriptionIds,
  selectRefferalInfo,
} from '../store/selectors/entities';
import { selectUserCountry } from '../store/selectors/global';
import analyticsSaga from './analyticsSaga';
import termlySaga from './termlySaga';
import autoCheckoutSage from './autoCheckoutSage';

const startLoaderActions = [
  signUp.type.start,
  addPaymentCard.type.start,
  getRedirectionToken.type.start,
  getToken.type.start,
  renewSubscription.type.start,
  upgradeSubscription.type.start,
  recoverEmail.type.start,
  deleteBillAddress.type.start,
  updateBillAddress.type.start,
  setPaymentAsDefault.type.start,
  addBillAddress.type.start,
  createPayment.type.start,
  checkSorUser.type.start,
  changeProfileInfo.type.start,
  updateUserSocialNetworkLinks.type.start,
];

export function* updateSiteData({ customerId, country }) {
  yield delay(500);

  if (customerId || country) {
    const query = `${country ? `&uiCountry=${country}` : ''}`;
    const [products, plans] = yield all([
      yield put(getFastshopingProducts.withQuery(`?${query}`).action()),
      yield put(getFastshopingPlans.withQuery(`?${query}`).action()),
    ]);

    if (
      products.type === getFastshopingProducts.type.success &&
      plans.type === getFastshopingPlans.type.success
    ) {
      const productsList = products.payload.data.data;
      const planList = plans.payload.data.data;

      const productsWithThePlans = planCombiner(productsList, planList);

      if (productsWithThePlans?.length) {
        yield put({
          type: SET_PRODUCTS,
          payload: [...productsWithThePlans],
        });
      }
    }
  }

  if (country && country.length === 2) {
    yield put(getMetaData.withQuery(`?countryCode=${country}&uiCountry=${country}`).action());
  } else {
    yield put(getMetaData.action());
  }
}

export function* updateProducts({ payload: { customerId, country } }) {
  yield call(updateSiteData, { customerId, country });
}

function* fetchRefferalWorker({ payload }) {
  yield put(getUserRefferal.withQuery(`/${payload}`).action());
  while (true) {
    const response = yield take(getUserRefferal.type.success);

    if (response.payload) {
      const { customerId } = response.payload.data;
      const refData = yield select(selectRefferalInfo) || {};

      const country =
        refData.country && refData.country.length
          ? refData.country
          : process.env.GATSBY_INSTANCE_COUNTRY;

      yield call(updateSiteData, { customerId, country });
    }
  }
}

function* updateUserDetails(action) {
  const { meta, type } = action;
  const unsupportedCardPaypalCountries = ['xk'];
  const userCountry = meta.previousAction.payload.request.data.country;

  if (
    unsupportedCardPaypalCountries.includes(userCountry) &&
    (type === addBillAddress.type.success || type === updateBillAddress.type.success)
  ) {
    setNotification('info', {
      message:
        "Your billing country doesn't support Card Payments / PayPal. But You will be able to use Bit Coin and E-wallet",
      title: '',
    });
  }

  try {
    yield put(getUserDetails.action());
  } catch (error) {
    yield put(setLoader(false));
  } finally {
    yield put(setLoader(false));
  }
}

// eslint-disable-next-line require-yield
function* redirectToCustomerPortal(action) {
  const { payload } = action;
  const { redirectionToken } = payload.data;

  const expectedSubscriptionIds = yield select(selectExpectedSubscriptionIds);
  const userDetails = yield select(selectEntityUserDetails);
  const withIds = expectedSubscriptionIds
    ? `&expectedSubscriptionIds=${expectedSubscriptionIds.toString()}`
    : '';
  if (isClient) {
    const portalLink = getPortalLink(userDetails?.country?.toLowerCase());
    window.location.href = `${portalLink}/?tempToken=${redirectionToken}${withIds}`;
  }
}

function* showLoader() {
  yield put(setLoader(true));
}

function* checkEnrollAndGetProducts() {
  const refData = yield select(selectRefferalInfo);

  const isEnrollment = isClient && window.location.href.includes('enrollment');
  const tempToken = isClient && getSearchParam('tempTok', window.location.href);
  const beBackOfficeToken = isClient && getSearchParam('Token', window.location.href);
  const userSavedCountry = yield select(selectUserCountry);
  const queryCountry =
    isClient && getSearchParam('country', window.location.href)?.replace('/', '');

  if (refData && !isEnrollment && !queryCountry) {
    const { customerId } = refData;

    const country =
      refData.country && refData.country.length
        ? refData.country
        : process.env.GATSBY_INSTANCE_COUNTRY;

    yield call(updateSiteData, { country, customerId });
    return;
  }

  if (!isUserLogged() && !tempToken && !beBackOfficeToken) {
    const country = queryCountry || userSavedCountry || process.env.GATSBY_INSTANCE_COUNTRY;
    if (country && country.length) {
      yield call(updateSiteData, { country });
    }
    if (queryCountry) {
      let countryStr = queryCountry;

      try {
        if (country.includes('enrollment')) {
          countryStr = queryCountry.split('/')[0].replace('enrollment', '');
        }
      } catch (e) {
        console.log('e', e);
      }

      yield put(setUserStartCountry(countryStr));
    }
  }
}

const notify = (message) => {
  setNotification('error', {
    message,
    title: 'Error',
  });
};

// eslint-disable-next-line consistent-return
function* checkAnAbilityToUseCoupon(action) {
  try {
    const { products } = yield select(selectCart);
    const details = yield select(selectEntityUserDetails);
    const cartPlanInfo = products.map(({ rebillyPlanIds, isRenew, isUpgrade }) => ({
      rebillyPlanIds,
      RENEW: isRenew,
      UPGRADE: isUpgrade,
      INITIAL: !isRenew && !isUpgrade,
    }));

    const {
      planIds: couponPlanIds,
      couponCode,
      forAction,
      elite,
      title,
      usageLeft,
      countries,
      validated,
    } = action.payload;

    const userCountry = details && details.country;
    const isAvailableForUserCountry = countries.includes(userCountry);

    const found =
      !forAction ||
      cartPlanInfo.some(
        (product) =>
          product.rebillyPlanIds.some((id) => couponPlanIds.indexOf(id) >= 0) &&
          product[forAction.toUpperCase()]
      );

    const elitePlan = products?.find((item) => item?.product?.name.toLowerCase() === 'elite');

    if (!products.length) {
      return notify("You don't have any plans in your shopping cart");
    }
    if (!isAvailableForUserCountry) {
      return notify("This coupon can't be used in your country");
    }
    if (products.length > 1 && title === 'FREELIVE') {
      return notify("You couldn't apply this coupon for selected plans");
    }
    if ((!found && products.length) || (elite && !elitePlan)) {
      return notify("You couldn't apply this coupon for selected plans");
    }
    if (usageLeft < 1) {
      return notify('You had exhausted the limit of possible coupon usages.');
    }
    if (found && products.length && validated) {
      yield put(preselectCoupon(couponCode));
    }

    const [response] = yield all([
      yield put(getCouponById.withQuery(`?code=${couponCode}`).action()),
    ]);

    if (response.type === getCouponById.type.success) {
      yield put(preselectCoupon(response.payload.data.data.couponCode));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

function* onCountrySelect({ payload }) {
  yield call(updateSiteData, { country: payload });
}

function* setAuditTrailStatusWorker(data) {
  const { step, externalId } = data?.payload;
  const auditTrial = yield select(selectAuditTrial);

  if (
    isUserLogged() &&
    step > auditTrial.step &&
    isClient &&
    !localStorage.getItem('audit-trial-id')
  ) {
    let auditTrialData = { ...auditTrial, step };
    let auditTrialRequestData = {
      type: Object.values(SIGNING_AUDIT_TRAIL_TYPE).find((v) => v.step === step).type,
      ...(auditTrial?.id ? { externalId: auditTrial?.id } : {}),
    };

    if (step !== SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step && (externalId || auditTrial?.id)) {
      auditTrialRequestData = {
        ...auditTrialRequestData,
        externalId: externalId || auditTrial?.id,
      };
    }

    const [res] = yield all([yield put(setAuditTrail.action(auditTrialRequestData))]);
    const externalIdDataResponse = res.payload?.data?.data;

    if (
      step === SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step &&
      res.type !== setAuditTrail.type.error &&
      externalIdDataResponse
    ) {
      auditTrialData = { ...auditTrialData, id: externalIdDataResponse };
    }

    if (step === SIGNING_AUDIT_TRAIL_TYPE.SIGNING_COMPLETED.step) {
      localStorage.setItem('audit-trial-id', auditTrial.id);
    }

    if (step === SIGNING_AUDIT_TRAIL_TYPE.CANCELED.step) {
      auditTrialData = { id: '', step: 0 };
    }

    yield put(setAuditTrialData(auditTrialData));
  }
}

function* redirectToCheckout() {
  yield call(createNavigateTo(pageLinks.simpleCheckout));
}

function* editCartItems() {
  yield delay(2000); // TODO: test without delay and remove it
  const ibpProduct = yield select(selectIbpProduct);

  if (ibpProduct) {
    yield put(addToCart('product', ibpProduct));
    yield put(getSignedAgreement());
  }
  return null;
}

function* mySaga() {
  if (!isLocalStorageAvailable() || !isClient) {
    return;
  }

  if (isClient) {
    window.getEnvConfig = getEnvConfig;
  }

  yield all([
    refreshTokenSaga(),
    authSaga(),
    tokenSaga(),
    paymentSaga(),
    errorSaga(),
    checkEnrollAndGetProducts(),
    // liveChatSaga(),
    analyticsSaga(),
    termlySaga(),
    autoCheckoutSage(),
    yield takeLatest(startLoaderActions, showLoader),
    yield takeLatest(AppConstants.CHECK_COUPON, checkAnAbilityToUseCoupon),
    yield takeLatest(FETCH_REFFERIAL_INFO, fetchRefferalWorker),
    yield takeLatest(getRedirectionToken.type.success, redirectToCustomerPortal),
    yield takeLatest(AppConstants.UPDATE_PRODUCT_LIST, onCountrySelect),
    yield takeLatest(
      [deleteBillAddress.type.success, updateBillAddress.type.success, addBillAddress.type.success],
      updateUserDetails
    ),
    yield takeLatest(changeProfileInfo.type.success, redirectToCheckout),
    yield takeLatest(AppConstants.UPDATE_PRODUCTS, updateProducts),
    yield takeLatest(AppConstants.SET_AUDIT_TRAIL_STATUS, setAuditTrailStatusWorker),
    yield takeLatest(getUserSubscriptions.type.success, editCartItems),
  ]);
}

export default mySaga;
