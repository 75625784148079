import clsx from 'clsx';

import React from 'react';
import DatePicker from 'react-datepicker';

import PropTypes, { oneOfType } from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './inputDatePicker.module.scss';

const InputDatePicker = (props) => {
  const {
    onChange,
    labelClassName,
    id,
    label,
    placeholder,
    rightSideLink,
    required,
    errorMessage,
    minDate,
    showMonthDropdown,
    format,
    value,
    maxDate
  } = props;

  return (
    <div className={clsx(styles.wrapper)}>
      <div className={clsx(styles.labelRow)}>
        {label ? (
          <label className={clsx('basicLabel', labelClassName)} htmlFor={id}>
            {label}
            {required && (
              <span aria-hidden className="asterisk">
                *
              </span>
            )}
          </label>
        ) : null}
        {rightSideLink}
      </div>

      <div className={clsx(styles.pickerWrapper)}>
        <DatePicker
          selected={value ? new Date(value) : ''}
          dateFormat={format}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown
          dropdownMode="select"
          id={id}
          placeholderText={placeholder}
          className={clsx(styles.input, { [styles.errorInput]: errorMessage })}
        />
        <i className={clsx('icon-calendar', styles.icon)} />
        {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
      </div>
    </div>
  );
};

InputDatePicker.defaultProps = {
  label: null,
  labelClassName: null,
  placeholder: '',
  rightSideLink: null,
  required: null,
  errorMessage: '',
  showMonthDropdown: false,
  format: '',
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

InputDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rightSideLink: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  showMonthDropdown: PropTypes.bool,
  format: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default InputDatePicker;
